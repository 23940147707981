import { render, staticRenderFns } from "./_productId.vue?vue&type=template&id=f6d3ba94"
import script from "./_productId.vue?vue&type=script&lang=js"
export * from "./_productId.vue?vue&type=script&lang=js"
import style0 from "./_productId.vue?vue&type=style&index=0&id=f6d3ba94&prod&lang=css"
import style1 from "./_productId.vue?vue&type=style&index=1&id=f6d3ba94&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports